const trackData = [
  {
    name: "Simple",
    spotify_id: "7hVK6T6lsXFeTIxTlHGhGU",
    applemusic_id: "1566171061",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b2731737c8953fa99db565fe7c9a",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e021737c8953fa99db565fe7c9a",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d000048511737c8953fa99db565fe7c9a",
        width: 64,
      },
    ],
    duration: 182180,
  },
  {
    name: "Pick Me Up",
    spotify_id: "6VYIVgVqEIwzRwpJz5rMa4",
    applemusic_id: "1566622020",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27345b802485b79ab7312863652",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0245b802485b79ab7312863652",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485145b802485b79ab7312863652",
        width: 64,
      },
    ],
    duration: 174100,
  },
  {
    name: "What Other People Say",
    spotify_id: "2h9TDNEXRhcDIV3fsoEVq9",
    applemusic_id: "1548028024",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b2730276236d403fd127cbb3dd68",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e020276236d403fd127cbb3dd68",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d000048510276236d403fd127cbb3dd68",
        width: 64,
      },
    ],
    duration: 194890,
  },
  {
    name: "This City",
    spotify_id: "3H3r2nKWa3Yk5gt8xgmsEt",
    applemusic_id: "1490897609",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27370e6c775adc0f71e6bef0a9b",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0270e6c775adc0f71e6bef0a9b",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485170e6c775adc0f71e6bef0a9b",
        width: 64,
      },
    ],
    duration: 194853,
  },
  {
    name: "For Now",
    spotify_id: "5wV2TAUTKHcKgaEB6XnWDI",
    applemusic_id: "1539431524",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b2738cdade6624acb7517cd49424",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e028cdade6624acb7517cd49424",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d000048518cdade6624acb7517cd49424",
        width: 64,
      },
    ],
    duration: 211812,
  },
  {
    name: "The Usual",
    spotify_id: "13GDcQRwrRwMxa9s774D9X",
    applemusic_id: "1522512346",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27307e21778a2523a2f43cd70a5",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0207e21778a2523a2f43cd70a5",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485107e21778a2523a2f43cd70a5",
        width: 64,
      },
    ],
    duration: 212953,
  },
  {
    name: "Everybody's Got Somebody",
    spotify_id: "01dFYow3U0jhbM6UZPaZlq",
    applemusic_id: "1522512351",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27307e21778a2523a2f43cd70a5",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0207e21778a2523a2f43cd70a5",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485107e21778a2523a2f43cd70a5",
        width: 64,
      },
    ],
    duration: 190869,
  },
  {
    name: "I Got To Live",
    spotify_id: "2KYQZh7OChW4NPkPyrFVGg",
    applemusic_id: "1522512354",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27307e21778a2523a2f43cd70a5",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0207e21778a2523a2f43cd70a5",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485107e21778a2523a2f43cd70a5",
        width: 64,
      },
    ],
    duration: 210426,
  },
  {
    name: "Ready",
    spotify_id: "1IkvWiW9eBqwF4ZF3oIm7h",
    applemusic_id: "1522512355",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27307e21778a2523a2f43cd70a5",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0207e21778a2523a2f43cd70a5",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485107e21778a2523a2f43cd70a5",
        width: 64,
      },
    ],
    duration: 161292,
  },
  {
    name: "People I Don't Know",
    spotify_id: "67LVked2xWltlu5POH71gk",
    applemusic_id: "1522512356",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b27307e21778a2523a2f43cd70a5",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e0207e21778a2523a2f43cd70a5",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d0000485107e21778a2523a2f43cd70a5",
        width: 64,
      },
    ],
    duration: 125694,
  },
  {
    name: "Smoke",
    spotify_id: "3UGidU0p612aXibfHxX1fk",
    applemusic_id: "1494510607",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b273e0a2d769344afd29725f110e",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e02e0a2d769344afd29725f110e",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d00004851e0a2d769344afd29725f110e",
        width: 64,
      },
    ],
    duration: 215683,
  },
  {
    name: "Getting Older",
    spotify_id: "4QUDFTtYeYjhfIeyDyulQc",
    applemusic_id: "1494510521",
    image: [
      {
        height: 640,
        url: "https://i.scdn.co/image/ab67616d0000b273e0a2d769344afd29725f110e",
        width: 640,
      },
      {
        height: 300,
        url: "https://i.scdn.co/image/ab67616d00001e02e0a2d769344afd29725f110e",
        width: 300,
      },
      {
        height: 64,
        url: "https://i.scdn.co/image/ab67616d00004851e0a2d769344afd29725f110e",
        width: 64,
      },
    ],
    duration: 209518,
  },
];

export default trackData;
