import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableTrack from "./draggableTrack";

const Column = (props) => {
  return (
    <div>
      <Droppable droppableId={props.column.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`[ b-track__grid ]${
              props.isDraggingOver ? "is-active" : ""
            }`}
          >
            {props.column.tracks.map((track, index) => {
              return track ? (
                <DraggableTrack
                  key={track.spotify_id}
                  track={track}
                  selected={
                    props.column.selected.indexOf(track.spotify_id) !== -1
                  }
                  index={index}
                  toggleTrack={props.toggleTrack}
                />
              ) : (
                ""
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default Column;
