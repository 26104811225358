import * as React from "react";
import { Draggable } from "react-beautiful-dnd";

const DraggableTrack = (props) => {
  return (
    <Draggable draggableId={props.track.spotify_id} index={props.index}>
      {(provided, snapshot) => (
        <div
          className={`[ b-track__item ]${
            props.selected ? "" : "[ is-disabled ]"
          }${snapshot.isDragging ? "[ is-dragging ]" : ""}`}
          onClick={(event) => props.toggleTrack(props.track.spotify_id)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onDragStart={() => {
            if (window.navigator.vibrate) {
              window.navigator.vibrate(100);
            }
          }}
          ref={provided.innerRef}
        >
          <div className="[ b-track__art ]">
            <img
              src={props.track.image[0].url}
              height={props.track.image[0].height}
              width={props.track.image[0].width}
              alt={props.track.name}
            />
          </div>
          <div className="[ b-track__detail ]">
            <h2 className="[ b-track__title ]">{props.track.name}</h2>
            <p className="[ b-track__artist ]">Sam Fischer</p>
          </div>
          {/*
            <Data>Spotify ID: ({props.track.spotify_id})</Data>
            <Data>Apple Music ID: ({props.track.applemusic_id})</Data>
            */}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableTrack;
