import React from 'react';
import Helmet from 'react-helmet';
import { DragDropContext } from 'react-beautiful-dnd';
import { window } from 'browser-monads';
import ReactModal from 'react-modal';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Column from '../components/column';
import SpotifyPlaylistEmbed from '../components/spotifyPlaylistEmbed';
import AppleMusicPlaylistEmbed from '../components/applemusicPlaylistEmbed';
import trackData from '../components/trackData';
import AeButton from '../components/aeButton';
import iconFacebook from '../images/facebook.svg';
import iconTwitter from '../images/twitter.svg';
import iconLink from '../images/link.svg';
import { stringify } from 'query-string';
import Base64 from 'crypto-js/enc-base64';
import Hex from 'crypto-js/enc-hex';
import { arrayMoveMutable } from 'array-move';

const validServices = {
  spotify: 'Spotify',
  applemusic: 'Apple Music',
};
class ListPage extends React.Component {
  state = {
    showModal: false,
    copyText: 'Copy Link',
    returnLink: [window.location.protocol, '//', window.location.host, window.location.pathname, window.location.hash].join(''),
    columns: [
      {
        id: 'trackListing',
        title: 'Track Listing',
        tracks: trackData,
        selected: trackData.map((track) => track.spotify_id),
      },
    ],
    aeLoaded: false,
    aeTimerId: false,
    timestamp: false,
    playlistType: '',
    playlist: false,
    playlistNeedsRefresh: false,
    playlistSrc: '',
    user: {},
  };

  componentDidMount() {
    this.startAeTimer();
    this.recoverTrackList();
  }

  shouldComponentUpdate(nextState) {
    /*
   TODO this was disabled to allow for selected track state update to update component - this may need to be put back in
   if (nextState.aeLoaded && !this.state.aeLoaded) {
      return true;
    }
    if (nextState.playlistSrc && !Boolean(this.state.playlistSrc)) {
      return true;
    }
    if (nextState.playlist && !this.state.playlist) {
      this.setState({
        playlistNeedsRefresh: true,
      });
      return true;
    }*/
    return true;
  }

  aeTimer = async () => {
    if (typeof window.aeJS !== 'undefined') {
      clearInterval(this.state.aeTimerId);
      window.aeJS.events.onLogout.addHandler((event) => {
        this.setState({
          playlistType: '',
          playlist: false,
          playlistNeedsRefresh: false,
          playlistSrc: '',
          user: {},
        });
      });
      window.aeJS.events.onLogin.addHandler((event) => {
        this.getUser();
      });
      window.aeJS.events.onUser.addHandler((event) => {
        this.getUser();
      });
      this.getUser();
      this.setState({
        aeLoaded: true,
        aeTimer: false,
      });
    }
  };

  startAeTimer = () => {
    const aeTimerId = setInterval(this.aeTimer, 100);
    this.setState({ aeTimerId });
  };

  getUser = () => {
    if (!window.aeJS.loggedIn) {
      return;
    }
    const user = window.aeJS.get.user();
    if (user?.services?.length > 0) {
      user.services = user.services.filter((service) => Object.keys(validServices).indexOf(service.Service) !== -1);
      this.setState({
        user: user,
      });
    }
    return;
  };

  recoverTrackList = () => {
    const hash = window.location.hash;
    if (hash === '' || hash === '#') {
      return;
    }
    const words = Base64.parse(window.location.hash.substr(1));
    const tracks = Hex.stringify(words)
      .split('a')
      .filter((item) => item !== '');
    const trackData = [...this.state.columns[0].tracks];

    for (let x = 0; x < trackData.length; x++) {
      const fromIndex = trackData.map((item) => item.applemusic_id).indexOf(tracks[x]);
      if (fromIndex !== -1) {
        arrayMoveMutable(trackData, fromIndex, x);
      }
    }
    const order = trackData.filter((track) => tracks.indexOf(track.applemusic_id) !== -1).map((item) => item.spotify_id);
    this.setState({
      columns: [
        {
          id: 'trackListing',
          title: 'Track Listing',
          tracks: trackData,
          selected: order,
        },
      ],
    });
  };

  persistTrackList = (selected) => {
    const trackData = [...this.state.columns[0].tracks];
    const tracks = trackData.filter((track) => selected.indexOf(track.spotify_id) !== -1);
    const trackList = tracks.map((track) => track.applemusic_id).join('a');
    window.location.hash = Base64.stringify(Hex.parse(trackList + 'a'));
    this.setState({
      returnLink: [window.location.protocol, '//', window.location.host, window.location.pathname, window.location.hash].join(''),
    });
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // Bail early if there were no changes
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceTrackList = this.state.columns.find((column) => column.id === source.droppableId).tracks;
    const targetTrack = sourceTrackList.find((track) => track.spotify_id === draggableId);
    const destinationTrackList = this.state.columns.find((column) => column.id === destination.droppableId).tracks;

    if (destination.droppableId !== source.droppableId) {
      // Moved to other column
      sourceTrackList.splice(source.index, 1);
      destinationTrackList.splice(destination.index, 0, targetTrack);
    } else {
      // Moved in same column
      sourceTrackList.splice(source.index, 1);
      sourceTrackList.splice(destination.index, 0, targetTrack);
    }
    this.persistTrackList(this.state.columns[0].selected);
  };

  createPlaylist = async (service) => {
    const tracks = trackData.filter((track) => this.state.columns[0].selected.indexOf(track.spotify_id) !== -1);
    const trackIds = await tracks.map((track) => track[`${service.Service}_id`]);
    try {
      const req = await fetch('https://microservices.4thfloorcreative.co.uk/sfsetlist/createPlaylist', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tracks: trackIds,
          token: service.Token,
          type: service.Service,
          userId: service.UserID,
          playlist: {
            name: 'Sam Fischer - My dream setlist',
          },
        }),
      });
      const playlistData = await req.json();

      if (playlistData) {
        const playlist = service.Service === 'applemusic' ? playlistData.data : playlistData.playlist;
        this.setState({
          playlistType: service.Service,
          playlist,
        });
        // Delay loading of playlist embed to ensure it's propagated
        setTimeout(this.updatePlaylistSrc, 5000);
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  updatePlaylistSrc = () => {
    const playlistSrc =
      this.state.playlistType === 'applemusic'
        ? `https://embed.music.apple.com/gb/playlist/${this.state.playlist.id}`
        : `https://open.spotify.com/embed/playlist/${this.state.playlist.id}`;
    this.setState({
      playlistSrc,
    });
  };

  getDuration = () => {
    var minutes = 1000 * 60;
    let durationCounter = 0;
    const selectedTracks = trackData.filter((track) => this.state.columns[0].selected.indexOf(track.spotify_id) !== -1);
    for (const track of selectedTracks) {
      durationCounter += track.duration;
    }
    return Math.round(durationCounter / minutes);
  };

  copyCurrentURLToClipBoard = async () => {
    const url = [window.location.protocol, '//', window.location.host, window.location.pathname, window.location.hash].join('');
    try {
      await navigator.clipboard.writeText(url);
      this.setState({
        copyText: 'Link Copied',
      });
    } catch (error) {
      this.setState({
        copyText: 'Copy Error',
      });
    }
  };

  shareLink = (event) => {
    event.preventDefault();
    let type;
    let destination;
    const url = [window.location.protocol, '//', window.location.host, window.location.pathname, window.location.hash].join('');

    if (event.target.dataset.type !== undefined) {
      type = event.target.dataset.type;
    } else {
      type = event.target.parentNode.dataset.type;
    }

    if (type === 'facebook') {
      destination = `https://www.facebook.com/sharer.php?${stringify({
        u: url,
      })}`;
    } else {
      destination = `https://twitter.com/share?${stringify({
        url: url,
        text: 'Setlist Generator',
        hashtags: 'SamFischer,SetList',
      })}`;
    }

    window.open(
      destination,
      'targetWindow',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=800,
      height=600`
    );
  };

  toggleTrack = (id) => {
    const newList = [...this.state.columns[0].selected];
    const existingIndex = newList.indexOf(id);
    if (existingIndex !== -1) {
      newList.splice(existingIndex, 1);
    } else {
      newList.push(id);
    }
    this.setState(
      {
        columns: [
          {
            id: 'trackListing',
            title: 'Track Listing',
            tracks: [...this.state.columns[0].tracks],
            selected: newList,
          },
        ],
      },
      () => {
        this.persistTrackList(newList);
      }
    );
  };

  handleLogout = () => {
    if (typeof window.aeJS !== 'undefined') {
      window.aeJS.trigger.logout();
    }
  };

  handleOpenModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <Layout pageTitle={this.state.playlist ? 'Your Personal Setlist' : 'A Setlist Generator'}>
        <Seo title='Sam Fischer - Setlist Generator' />
        <Helmet>
          <script type='text/javascript' src='//sme.theappreciationengine.com/framework/js/497?segment=979167'></script>
          <script defer type='text/javascript' src='/scripts/aeHandler.js'></script>
        </Helmet>
        <ReactModal
          isOpen={this.state.showModal}
          onAfterOpen={() => {
            if (typeof window.aeJS !== 'undefined') {
              window.aeJS.trigger.attach();
            }
          }}
          contentLabel='onRequestClose Example'
          onRequestClose={this.handleCloseModal}
          className='[ b-modal ]'
          overlayClassName='[ b-modal-overlay ]'>
          <div className='[ b-modal__actions ]'>
            <p>Login to save and share your playlist</p>
            {Object.keys(validServices).map((service, key) => (
              <AeButton key={key} service={service} return={this.state.returnLink} text={`Login with ${validServices[service]}`} />
            ))}
          </div>
          <button onClick={this.handleCloseModal} className='[ b-modal__close ]'>
            <span className='[ u-hidden-visually ]'>Close Modal</span>
          </button>
        </ReactModal>
        {this.state.playlist ? (
          <div className='[ b-playlist ]'>
            <div className='[ b-playlist__share ]'>
              <div className='[ b-playlist__share__remote ]'>
                <p className='[ b-playlist__share__title ]'>Share</p>
                <ul className='[ b-playlist__share__list ]'>
                  <li className='[ b-playlist__share__item ]'>
                    <a
                      href={`https://www.facebook.com/sharer.php?u=`}
                      onClick={this.shareLink}
                      data-type='facebook'
                      target='_blank'
                      className='[ b-playlist__share__link ]'
                      rel='noopener noreferrer'>
                      <img src={iconFacebook} alt='Share on Facebook' />
                    </a>
                  </li>
                  <li className='[ b-playlist__share__item ]'>
                    <a
                      href={`https://twitter.com/share?url=`}
                      target='_blank'
                      data-type='twitter'
                      onClick={this.shareLink}
                      className='[ b-playlist__share__link ]'
                      rel='noopener noreferrer'>
                      <img src={iconTwitter} alt='Share on Twitter' />
                    </a>
                  </li>
                </ul>
              </div>
              <p className='[ b-playlist__copy ]'>
                <button className='[ b-playlist__copy__link ]' onClick={this.copyCurrentURLToClipBoard}>
                  {this.state.copyText} <img src={iconLink} alt='Share the website link' />
                </button>
              </p>
            </div>
            <div className='[ b-playlist__ ]'>
              {(() => {
                switch (this.state.playlistType) {
                  case 'spotify':
                    return (
                      <SpotifyPlaylistEmbed
                        playlistNeedsRefresh={this.state.playlistNeedsRefresh}
                        playlistId={this.state.playlist.id}
                        src={this.state.playlistSrc}
                      />
                    );
                  case 'applemusic':
                    return (
                      <AppleMusicPlaylistEmbed
                        playlistNeedsRefresh={this.state.playlistNeedsRefresh}
                        playlistId={this.state.playlist.id}
                        src={this.state.playlistSrc}
                      />
                    );
                  default:
                    return <p>Sorry we could not display this playlist ({this.state.playlistType ? this.state.playlistType : 'No Playlist selected'})</p>;
                }
              })()}
            </div>
            <div className='[ b-playlist__actions ]'>
              <a className='[ u-button u-button--accent ]' href='https://www.samfischermusic.com/live/' target='_blank' rel='noopener noreferrer'>
                Book Tour Tickets
              </a>
              <a
                className='[ u-button ]'
                href='https://forms.sonymusicfans.com/campaign/rca_samfischer_setlist_gen_entry_form_2021/'
                target='_blank'
                rel='noopener noreferrer'>
                Tap for the chance to win tickets
              </a>
            </div>
          </div>
        ) : (
          <div className='[ b-playlist ]'>
            <div className='[ b-playlist__intro ]'>
              <dl className='[ b-playlist__meta ]'>
                <dt className='[ b-playlist__meta__name ]'>Tracks</dt>
                <dd className='[ b-playlist__meta__value ]'>
                  {this.state.columns[0].selected.length}/{trackData.length}
                </dd>
                <dt className='[ b-playlist__meta__name ]'>Duration</dt>
                <dd className='[ b-playlist__meta__value ]'>{this.getDuration()}m</dd>
              </dl>
            </div>

            <DragDropContext onDragEnd={this.onDragEnd}>
              {this.state.columns.map((column) => {
                return <Column key={column.id} column={column} toggleTrack={this.toggleTrack} />;
              })}
            </DragDropContext>

            <div className='[ b-playlist__actions ]'>
              {this.state.user?.services?.length > 0 &&
                this.state.user.services.map((service) => (
                  <button key={service.Service} className='[ u-button ]' onClick={() => this.createPlaylist(service)}>
                    Save to setlist <span>({validServices[service.Service]})</span>
                  </button>
                ))}
              {(!this.state.user?.services || this.state.user?.services?.length === 0) && (
                <button className='[ u-button ]' onClick={this.handleOpenModal}>
                  Login to save
                </button>
              )}
              {this.state.user?.services?.length > 0 && (
                <button className='[ u-button ]' onClick={this.handleLogout}>
                  Logout
                </button>
              )}
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

export default ListPage;
