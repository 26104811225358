import * as React from "react";
import styled from "styled-components";

const Playlist = styled.iframe`
  width: 100%;
  height: ${(props) => (props.src ? `300` : `100`)}px;
  border: none;
  outline: none;
  pointer-events: ${(props) => (props.playlistNeedsRefresh ? `none` : `all`)};
  background-image: url("/img/loader.gif");
  background-position: center center;
  background-size: 100px 100px;
  background-repeat: no-repeat;
`;

const PlaylistEmbed = (props) => (
  <Playlist
    allow="autoplay *; encrypted-media *; fullscreen *"
    frameborder="0"
    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    src={props.src}
  ></Playlist>
);

export default PlaylistEmbed;
