import * as React from "react";
import { Link } from "gatsby";
import spotify from "../images/spotify.svg";
import apple from "../images/apple.svg";

const AeButton = (props) => {
  return (
    <Link
      to="#"
      data-ae-register-link={props.service}
      data-ae-return={props.return}
      className={`[ u-button u-button--icon u-button--${props.service} ]`}
      id={`${props.service}LoginButton`}
    >
      <span className="[ u-button__flex ]">
        <img
          src={props.service === "applemusic" ? apple : spotify}
          alt={props.service}
          className="[ u-button__icon ]"
        />
        <span className="[ u-button__text ]">{props.text}</span>
      </span>
    </Link>
  );
};

export default AeButton;
